import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import Section from "../../components/Section"
import { Link } from "gatsby"
import BadgeDownload from "../../components/BadgeDownload"
import ImageDownloadRow from "../../components/ImageDownloadRow"


const IndexPage = () => {
    const root = "/img/download/awards"


    return (
        <Layout pageTitle="Logos & Badges">
            <Row>
                <Col>
                    <Link to="/awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <p className="para">
                        Use these award badges to announce your nomination on social media. 
                        You can also add these to your email and website footers.
                        Select 'transparent background' when downloading to remove the white backgroud if needed to put over and image or coloured background.
                    </p>
                   
                    
                </Col>
            </Row>
            <BadgeDownload
                root={root}
                name="Award Nominee Badge"
                filename="award-nominee-badge"
                text="Use this to shout about your nomination! You don't need to purchase your ticket at this point, but if you do wish to proceed as a finalist you can book your ticket below and use the Finalist badge below."
                squareHref="https://www.canva.com/design/DAFQtuPd4dg/wiA8pQSGC59T8waMo43eBA/view?utm_content=DAFQtuPd4dg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                bookHref="https://womensbusiness.club/awards"
           
           />
            <BadgeDownload
                root={root}
                name="Award Finalist Badge"
                filename="award-finalist-badge"
                text="Use this to shout about being an official finalist! You will need to first purchase your ticket at the link above to become an award finalist and be entered into the finals of the awards."
                squareHref="https://www.canva.com/design/DAFQtu3GcIM/7lkPGM2k4H879Wuzncp9rw/view?utm_content=DAFQtu3GcIM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            />
            <ImageDownloadRow
                root={root}
                name="Awards Logo Blue"
                filename="awards-logo-blue"
                text=""
            />
             <ImageDownloadRow
                root={root}
                name="Awards Logo White"
                filename="awards-logo-white"
                text=""
            />
        </Layout>
    )
}




export default IndexPage
